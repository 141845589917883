import React, { useState } from 'react';
import MainPage from './MainPage';
import CompanyVision from './CompanyVision';
import UseCaseLeft from './UseCaseLeft';
import UseCaseRight from './UseCaseRight';
import individualsImage from '../assets/individuals.jpg';
import businessImage from '../assets/businesses.jpg';
import groupsImage from '../assets/groups.webp';
import individualsLogo from '../assets/IndividualsLogo.webp';
import groupsLogo from '../assets/GroupsLogo.webp';
import businessLogo from '../assets/BusinessLogo.webp';
import BottomCTA from './BottomCTA';

function Home() {
  const [formEmail, setFormEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [formEmailBottom, setFormEmailBottom] = useState('');
  const [errorMessageBottom, setErrorMessageBottom] = useState('');
  const [successfulSubmitBottom, setSuccessfulSubmitBottom] = useState(false);

  const individuals = {
    title: 'Individuals',
    subtitle: 'Your personal CRM',
    highlighted: 'See all your relationships',
    description: ' at a glance and grow the ones that matter to you.',
  };

  const business = {
    title: 'Businesses',
    subtitle: 'Digital mirror of your business',
    highlighted: 'Sell products and services',
    description: ', online and in-person, and interact with your community of customers with the simplicity of a messaging app.',
  };

  const groups = {
    title: 'Groups',
    subtitle: 'Your digital hangout',
    highlighted: 'Pool money together',
    description: ', boost your group’s unique antics and organize events easily.',
  };

  function isValidEmail(value) {
    const regex = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
    return regex.test(value);
  }

  async function create(email) {
    const data = {
      email,
    };

    const endpoint = '/data-api/rest/person/';
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return response.json();
    // console.log(result.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!isValidEmail(formEmail)) {
      setErrorMessage('Please enter a valid email address');
    } else {
      create(formEmail)
        .then(() => {
          // console.log(response);
          setErrorMessage('');
          setSuccessfulSubmit(true);
          setFormEmail('');
        })
        .catch(() => {
          // console.log(error);
          setErrorMessage('Error while registering the form !');
          setSuccessfulSubmit(false);
        });
    }
  }

  function handleSubmitBottom(event) {
    event.preventDefault();
    if (!isValidEmail(formEmailBottom)) {
      setErrorMessageBottom('Please enter a valid email address');
    } else {
      create(formEmailBottom)
        .then(() => {
          // console.log(response);
          setErrorMessageBottom('');
          setSuccessfulSubmitBottom(true);
          setFormEmailBottom('');
        })
        .catch(() => {
          // console.log(error);
          setErrorMessageBottom('Error while registering the form !');
          setSuccessfulSubmitBottom(false);
        });
    }
  }

  return (
    <>
      <MainPage
        email={formEmail}
        setFormEmail={setFormEmail}
        handleSubmit={handleSubmit}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        successfulSubmit={successfulSubmit}
        setSuccessfulSubmit={setSuccessfulSubmit}
      />
      <CompanyVision />
      <UseCaseLeft {...individuals} thumbnail={individualsImage} titleLogo={individualsLogo} />
      <UseCaseRight {...groups} thumbnail={groupsImage} titleLogo={groupsLogo} />
      <UseCaseLeft {...business} thumbnail={businessImage} titleLogo={businessLogo} />
      <BottomCTA
        email={formEmailBottom}
        setFormEmail={setFormEmailBottom}
        handleSubmit={handleSubmitBottom}
        errorMessage={errorMessageBottom}
        setErrorMessage={setErrorMessageBottom}
        successfulSubmit={successfulSubmitBottom}
        setSuccessfulSubmit={setSuccessfulSubmitBottom}
      />
    </>
  );
}

export default Home;
