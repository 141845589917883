import React from 'react';
import './styles.scss';

function Page404() {
  return (
    <div className="page404">
      <div className="content404">
        <div className="error404">404</div>
        <div className="errorMessage"><p>This page doesn&apos;t exist</p></div>
      </div>
    </div>
  );
}

export default Page404;
