import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

function UseCaseLeft({
  title, description, subtitle, thumbnail, titleLogo, highlighted,
}) {
  return (
    <div className="page">
      <div className="page_content">
        <div className="page_text">
          <div className="title">
            <h2>{title}</h2>
            <img className="title_image_left" src={titleLogo} alt={`${title} bird logo`} />
          </div>
          <div className="description">
            <p className="subtitle">{subtitle}</p>
            <p>
              <span className="highlighted">{highlighted}</span>
              {description}
            </p>
          </div>
        </div>
        <div className="use_case_image">
          <img src={thumbnail} alt="woman using her smartphone in the street" />
        </div>
      </div>
    </div>
  );
}

UseCaseLeft.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleLogo: PropTypes.node.isRequired,
};

export default UseCaseLeft;
