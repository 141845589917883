import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';
import logo from '../assets/logo.svg';

function Footer() {
  return (
    <div className="footer">
      <div className="left_content">
        <div className="about">
          <img src={logo} alt="blue Coop logo representing a rounded chicken in the shape of a message bubble" />
          <h3>About</h3>
        </div>
        <div className="page_links">
          <Link to="/legal-notice">
            <p>Legal Notice</p>
          </Link>
          <Link to="/terms-of-use">
            <p>Terms of Use</p>
          </Link>
          <Link to="/privacy">
            <p>Privacy Policy</p>
          </Link>
        </div>
        <p className="contact">
          Contact:&nbsp;
          <a href="mailto:contact@lecoop.co">contact@lecoop.co</a>
        </p>
      </div>
      <div className="right_content">
        <h3>Follow us</h3>
        <div className="socials">
          <Link to="https://www.instagram.com/coop.app.official">
            <FaInstagramSquare className="instagram" />
          </Link>
          <Link to="https://www.twitter.com/CoopAppOfficial">
            <FaTwitterSquare className="twitter" />
          </Link>
        </div>
      </div>
      <div className="copyright">
        <p>Copyright &copy; 2023 Coop</p>
      </div>
    </div>
  );
}

export default Footer;
