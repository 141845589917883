import React, { useEffect } from 'react';
import './styles.scss';

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="privacy">
      <h1>
        Privacy Policy
      </h1>
      <p>
        Last updated: April 25, 2023
      </p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the Service
        and tells You about Your privacy rights and how the law protects You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using the
        Service, You agree to the collection and use of information in accordance
        with this Privacy Policy.
      </p>
      <div>
        <h2>
          Interpretation and Definitions
        </h2>
      </div>
      <h3>
        Interpretation
      </h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined
        under the following conditions. The following definitions shall have the
        same meaning regardless of whether they appear in singular or in plural.
      </p>
      <h3>
        Definitions
      </h3>
      <p>
        For the purposes of this Privacy Policy:
      </p>
      <p>
        <strong>Account</strong>
        &nbsp;
        means a unique account created for You to access our Service or parts of
        our Service.
      </p>
      <p>
        <strong>Affiliate</strong>
        &nbsp;
        means an entity that controls, is controlled by or is under common control
        with a party, where &quot;control&quot; means ownership of 50% or more of the shares,
        equity interest or other securities entitled to vote for election of
        directors or other managing authority.
      </p>
      <p>
        <strong>Company</strong>
        &nbsp;
        (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
        Agreement) refers to Coop SAS.
      </p>
      <p>
        For the purpose of the GDPR, the Company is the Data Controller.
      </p>
      <p>
        <strong>Cookies</strong>
        &nbsp;
        are small files that are placed on Your computer, mobile device or any
        other device by a website, containing the details of Your browsing history
        on that website among its many uses.
      </p>
      <p>
        <strong>Country</strong>
        &nbsp;
        refers to: France
      </p>
      <p>
        <strong>Data Controller</strong>
        &nbsp;
        , for the purposes of the GDPR (General Data Protection Regulation), refers
        to the Company as the legal person which alone or jointly with others
        determines the purposes and means of the processing of Personal Data.
      </p>
      <p>
        <strong>Device</strong>
        &nbsp;
        means any device that can access the Service such as a computer, a
        cellphone or a digital tablet.
      </p>
      <p>
        <strong>GDPR</strong>
        &nbsp;
        refers to EU General Data Protection Regulation.
      </p>
      <p>
        <strong>Personal Data</strong>
        &nbsp;
        is any information that relates to an identified or identifiable
        individual.
      </p>
      <p>
        For the purposes of GDPR, Personal Data means any information relating to
        You such as a name, an identification number, location data, online
        identifier or to one or more factors specific to the physical,
        physiological, genetic, mental, economic, cultural or social identity.
      </p>
      <p>
        <strong>Service</strong>
        &nbsp;
        refers to the Website.
      </p>
      <p>
        <strong>Service Provider</strong>
        &nbsp;
        means any natural or legal person who processes the data on behalf of the
        Company. It refers to third-party companies or individuals employed by the
        Company to facilitate the Service, to provide the Service on behalf of the
        Company, to perform services related to the Service or to assist the
        Company in analyzing how the Service is used. For the purpose of the GDPR,
        Service Providers are considered Data Processors.
      </p>
      <p>
        <strong>Usage Data</strong>
        &nbsp;
        refers to data collected automatically, either generated by the use of the
        Service or from the Service infrastructure itself (for example, the
        duration of a page visit).
      </p>
      <p>
        <strong>Website</strong>
        &nbsp;
        refers to Coop, accessible from&nbsp;
        <a href="https://www.lecoop.co">https://www.lecoop.co</a>
      </p>
      <p>
        <strong>You</strong>
        &nbsp;
        means the individual accessing or using the Service, or the company, or
        other legal entity on behalf of which such individual is accessing or using
        the Service, as applicable.
      </p>
      <p>
        Under GDPR, You can be referred to as the Data Subject or as the User as
        you are the individual using the Service.
      </p>
      <div>
        <h2>
          Collecting and Using Your Personal Data
        </h2>
      </div>
      <h3>
        Types of Data Collected
      </h3>
      <h4>
        Personal Data
      </h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or identify
        You. Personally identifiable information may include, but is not limited
        to:
      </p>
      <p>
        Email address
      </p>
      <p>
        Usage Data
      </p>
      <h4>
        Usage Data
      </h4>
      <p>
        Usage Data is collected automatically when using the Service.
      </p>
      <p>
        Usage Data may include information such as Your Device&apos;s Internet Protocol
        address (e.g. IP address), browser type, browser version, the pages of our
        Service that You visit, the time and date of Your visit, the time spent on
        those pages, unique device identifiers and other diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may collect
        certain information automatically, including, but not limited to, the type
        of mobile device You use, Your mobile device unique ID, the IP address of
        Your mobile device, Your mobile operating system, the type of mobile
        Internet browser You use, unique device identifiers and other diagnostic
        data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You visit
        our Service or when You access the Service by or through a mobile device.
      </p>
      <h4>
        Tracking Technologies and Cookies
      </h4>
      <p>
        We don&apos;t use cookies.
      </p>
      <h3>
        Use of Your Personal Data
      </h3>
      <p>
        The Company may use Personal Data for the following purposes:
      </p>
      <p>
        <strong>To provide and maintain our Service</strong>
        , including to monitor the usage of our Service.
      </p>
      <p>
        <strong>To manage Your Account</strong>
        : to manage Your registration as a user of the Service. The Personal Data You
        provide can give You access to different functionalities of the Service
        that are available to You as a registered user.
      </p>
      <p>
        <strong>For the performance of a contract</strong>
        : the development, compliance and undertaking of the purchase contract for
        the products, items or services You have purchased or of any other contract
        with Us through the Service.
      </p>
      <p>
        <strong>To contact You</strong>
        : To contact You by email, telephone calls, SMS, or other equivalent forms of
        electronic communication, such as a mobile application&apos;s push notifications
        regarding updates or informative communications related to the
        functionalities, products or contracted services, including the security
        updates, when necessary or reasonable for their implementation.
      </p>
      <p>
        <strong>To provide You</strong>
        &nbsp; with news, special offers and general information about other goods,
        services and events which we offer that are similar to those that you have
        already purchased or enquired about unless You have opted not to receive
        such information.
      </p>
      <p>
        <strong>To manage Your requests</strong>
        : To attend and manage Your requests to Us.
      </p>
      <p>
        <strong>For business transfers</strong>
        : We may use Your information to evaluate or conduct a merger, divestiture,
        restructuring, reorganization, dissolution, or other sale or transfer of
        some or all of Our assets, whether as a going concern or as part of
        bankruptcy, liquidation, or similar proceeding, in which Personal Data held
        by Us about our Service users is among the assets transferred.
      </p>
      <p>
        <strong>For other purposes</strong>
        : We may use Your information for other purposes, such as data analysis,
        identifying usage trends, determining the effectiveness of our promotional
        campaigns and to evaluate and improve our Service, products, services,
        marketing and your experience.
      </p>
      <p>
        We may share Your personal information in the following situations:
      </p>
      <ul>
        <li>
          <strong>With Service Providers</strong>
          : We may share Your personal information with Service Providers to
          monitor and analyze the use of our Service, to contact You.
        </li>
        <li>
          <strong>For business transfers</strong>
          : We may share or transfer Your personal information in connection with,
          or during negotiations of, any merger, sale of Company assets,
          financing, or acquisition of all or a portion of Our business to
          another company.
        </li>
        <li>
          <strong>With Affiliates</strong>
          : We may share Your information with Our affiliates, in which case we
          will require those affiliates to honor this Privacy Policy. Affiliates
          include Our parent company and any other subsidiaries, joint venture
          partners or other companies that We control or that are under common
          control with Us.
        </li>
        <li>
          <strong>With business partners</strong>
          : We may share Your information with Our business partners to offer You
          certain products, services or promotions.
        </li>
        <li>
          <strong>With other users</strong>
          : when You share personal information or otherwise interact in the public
          areas with other users, such information may be viewed by all users and
          may be publicly distributed outside.
        </li>
        <li>
          <strong>With Your consent</strong>
          : We may disclose Your personal information for any other purpose with
          Your consent.
        </li>
      </ul>
      <h3>
        Retention of Your Personal Data
      </h3>
      <p>
        The Company will retain Your Personal Data only for as long as is necessary
        for the purposes set out in this Privacy Policy. We will retain and use
        Your Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to comply
        with applicable laws), resolve disputes, and enforce our legal agreements
        and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except when
        this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h3>
        Transfer of Your Personal Data
      </h3>
      <p>
        Your information, including Personal Data, is processed at the Company&apos;s
        operating offices and in any other places where the parties involved in the
        processing are located. It means that this information may be transferred
        to &#8212; and maintained on &#8212; computers located outside of Your
        state, province, country or other governmental jurisdiction where the data
        protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and no
        transfer of Your Personal Data will take place to an organization or a
        country unless there are adequate controls in place including the security
        of Your data and other personal information.
      </p>
      <h3>
        Delete Your Personal Data
      </h3>
      <p>
        You have the right to delete or request that We assist in deleting the
        Personal Data that We have collected about You.
      </p>
      <p>
        Our Service may give You the ability to delete certain information about
        You from within the Service.
      </p>
      <p>
        You may update, amend, or delete Your information at any time by signing in
        to Your Account, if you have one, and visiting the account settings section
        that allows you to manage Your personal information. You may also contact
        Us to request access to, correct, or delete any personal information that
        You have provided to Us.
      </p>
      <p>
        Please note, however, that We may need to retain certain information when
        we have a legal obligation or lawful basis to do so.
      </p>
      <h3>
        Disclosure of Your Personal Data
      </h3>
      <h4>
        Business Transactions
      </h4>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h4>
        Law enforcement
      </h4>
      <p>
        Under certain circumstances, the Company may be required to disclose Your
        Personal Data if required to do so by law or in response to valid requests
        by public authorities (e.g. a court or a government agency).
      </p>
      <h4>
        Other legal requirements
      </h4>
      <p>
        The Company may disclose Your Personal Data in the good faith belief that
        such action is necessary to:
      </p>
      <ul>
        <li>
          Comply with a legal obligation
        </li>
      </ul>
      <p>
        &#183; Protect and defend the rights or property of the Company
      </p>
      <p>
        &#183; Prevent or investigate possible wrongdoing in connection with the
        Service
      </p>
      <p>
        &#183; Protect the personal safety of Users of the Service or the public
      </p>
      <ul>
        <li>
          Protect against legal liability
        </li>
      </ul>
      <h3>
        Security of Your Personal Data
      </h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that no
        method of transmission over the Internet, or method of electronic storage
        is 100% secure. While We strive to use commercially acceptable means to
        protect Your Personal Data, We cannot guarantee its absolute security.
      </p>
      <div>
        <h2>
          Detailed Information on the Processing of Your Personal Data
        </h2>
      </div>
      <p>
        The Service Providers We use may have access to Your Personal Data. These
        third-party vendors collect, store, use, process and transfer information
        about Your activity on Our Service in accordance with their Privacy
        Policies.
      </p>
      <h3>
        Usage, Performance and Miscellaneous
      </h3>
      <p>
        We may use third-party Service Providers to provide better improvement of
        our Service.
      </p>
      <p>
        <strong>Invisible reCAPTCHA</strong>
      </p>
      <p>
        We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated
        by Google.
      </p>
      <p>
        The reCAPTCHA service may collect information from You and from Your Device
        for security purposes.
      </p>
      <p>
        The information gathered by reCAPTCHA is held in accordance with the
        Privacy Policy of Google:&nbsp;
        <a href="https://www.google.com/intl/en/policies/privacy/">
          https://www.google.com/intl/en/policies/privacy/
        </a>
      </p>
      <div>
        <h2>
          GDPR Privacy
        </h2>
      </div>
      <h3>
        Legal Basis for Processing Personal Data under GDPR
      </h3>
      <p>
        We may process Personal Data under the following conditions:
      </p>
      <ul>
        <li>
          <strong>Consent:</strong>
          &nbsp;
          You have given Your consent for processing Personal Data for one or
          more specific purposes.
        </li>
        <li>
          <strong>Performance of a contract:</strong>
          &nbsp;
          Provision of Personal Data is necessary for the performance of an
          agreement with You and/or for any pre-contractual obligations thereof.
        </li>
        <li>
          <strong>Legal obligations:</strong>
          &nbsp;
          Processing Personal Data is necessary for compliance with a legal
          obligation to which the Company is subject.
        </li>
        <li>
          <strong>Vital interests:</strong>
          &nbsp;
          Processing Personal Data is necessary in order to protect Your vital
          interests or of another natural person.
        </li>
        <li>
          <strong>Public interests:</strong>
          &nbsp;
          Processing Personal Data is related to a task that is carried out in
          the public interest or in the exercise of official authority vested in
          the Company.
        </li>
        <li>
          <strong>Legitimate interests:</strong>
          &nbsp;
          Processing Personal Data is necessary for the purposes of the
          legitimate interests pursued by the Company.
        </li>
      </ul>
      <p>
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
      </p>
      <h3>
        Your Rights under the GDPR
      </h3>
      <p>
        The Company undertakes to respect the confidentiality of Your Personal Data
        and to guarantee You can exercise Your rights.
      </p>
      <p>
        You have the right under this Privacy Policy, and by law if You are within
        the EU, to:
      </p>
      <ul>
        <li>
          <strong>Request access to Your Personal Data.</strong>
          &nbsp;
          The right to access, update or delete the information We have on You.
          Whenever made possible, you can access, update or request deletion of
          Your Personal Data directly within Your account settings section. If
          you are unable to perform these actions yourself, please contact Us to
          assist You. This also enables You to receive a copy of the Personal
          Data We hold about You.
        </li>
        <li>
          <strong>
            Request correction of the Personal Data that We hold about You.
          </strong>
          &nbsp;
          You have the right to have any incomplete or inaccurate information We
          hold about You corrected.
        </li>
        <li>
          <strong>Object to processing of Your Personal Data.</strong>
          &nbsp;
          This right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your
          particular situation, which makes You want to object to our processing
          of Your Personal Data on this ground. You also have the right to object
          where We are processing Your Personal Data for direct marketing
          purposes.
        </li>
        <li>
          <strong>Request erasure of Your Personal Data.</strong>
          &nbsp;
          You have the right to ask Us to delete or remove Personal Data when
          there is no good reason for Us to continue processing it.
        </li>
        <li>
          <strong>Request the transfer of Your Personal Data.</strong>
          &nbsp;
          We will provide to You, or to a third-party You have chosen, Your
          Personal Data in a structured, commonly used, machine-readable format.
          Please note that this right only applies to automated information which
          You initially provided consent for Us to use or where We used the
          information to perform a contract with You.
        </li>
        <li>
          <strong>Withdraw Your consent.</strong>
          &nbsp;
          You have the right to withdraw Your consent on using your Personal
          Data. If You withdraw Your consent, We may not be able to provide You
          with access to certain specific functionalities of the Service.
        </li>
      </ul>
      <h3>
        Exercising of Your GDPR Data Protection Rights
      </h3>
      <p>
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify Your
        identity before responding to such requests. If You make a request, We will
        try our best to respond to You as soon as possible.
      </p>
      <p>
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You are
        in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </p>
      <div>
        <h2>
          Children&apos;s Privacy
        </h2>
      </div>
      <p>
        Our Service does not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from anyone under the
        age of 13. If You are a parent or guardian and You are aware that Your
        child has provided Us with Personal Data, please contact Us. If We become
        aware that We have collected Personal Data from anyone under the age of 13
        without verification of parental consent, We take steps to remove that
        information from Our servers.
      </p>
      <p>
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may require
        Your parent&apos;s consent before We collect and use that information.
      </p>
      <div>
        <h2>
          Links to Other Websites
        </h2>
      </div>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that third
        party&apos;s site. We strongly advise You to review the Privacy Policy of every
        site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <div>
        <h2>
          Changes to this Privacy Policy
        </h2>
      </div>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You of
        any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last updated&quot; date
        at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on this
        page.
      </p>
      <div>
        <h2>
          Contact Us
        </h2>
      </div>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul>
        <li>
          By email: contact@lecoop.co
        </li>
      </ul>
    </div>
  );
}

export default Privacy;
