import React from 'react';
import './styles.scss';
import image from '../../assets/companyvision.webp';

function CompanyVision() {
  return (
    <div className="company_vision">
      <div className="company_content">
        <div className="text_content">
          <h3>
            <span className="main_blue">Coop</span>
            {' '}
            is your private home for relationships
          </h3>
          <p>
            The super-app that combines messaging, mobile payments and services to make human interactions easy, even when you are far.
          </p>
        </div>
        <div className="image_content">
          <img src={image} alt="3 groups of birds inside of a chicken coop, one group is texting, another is exchanging funds, and the last one is a single chicken surrounded by many things, which represents many different apps" />
        </div>
      </div>
    </div>
  );
}

export default CompanyVision;
