import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import logo from '../assets/logo.svg';

function NavBar() {
  return (
    <div className="nav_bar">
      <Link to="/">
        <img src={logo} alt="blue Coop logo representing a rounded chicken in the shape of a message bubble" />
      </Link>
      <Link to="/"><h3 className="coop">Coop</h3></Link>
    </div>
  );
}

export default NavBar;
