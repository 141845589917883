import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function UseCaseRight({
  title, description, subtitle, thumbnail, titleLogo, highlighted,
}) {
  return (
    <div className="page page_right">
      <div className="page_content page_content_right">
        <div className="use_case_image_right">
          <img src={thumbnail} alt="woman using her smartphone in the street" />
        </div>
        <div className="page_text page_text_right">
          <div className="title title_right">
            <h2>{title}</h2>
            <img className="title_image_right" src={titleLogo} alt={`${title} bird logo`} />
          </div>
          <div className="description">
            <p className="subtitle">{subtitle}</p>
            <p>
              <span className="highlighted">{highlighted}</span>
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

UseCaseRight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleLogo: PropTypes.node.isRequired,
};

export default UseCaseRight;
