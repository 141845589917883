import React, { useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

function BottomCTA({
  email,
  setFormEmail,
  handleSubmit,
  errorMessage,
  setErrorMessage,
  successfulSubmit,
  setSuccessfulSubmit,
}) {
  useEffect(() => {
    let timer;
    if (successfulSubmit) {
      timer = setTimeout(() => {
        setSuccessfulSubmit(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [successfulSubmit]);
  return (
    <div className="bottom_cta">
      <h3>We believe in a world of meaningful relationships, where we help each other thrive together</h3>
      <p>If you also believe in this world, leave your email below to let us inform you when we launch.</p>
      <div className="form_container">
        <form>
          <label>
            <input
              type="email"
              className="form_input"
              value={email}
              placeholder="example@mail.com"
              onChange={(event) => {
                // console.log(event.target.value);
                setFormEmail(event.target.value);
                setErrorMessage('');
              }}
            />
          </label>
          <button
            className="submit_button"
            type="submit"
            value={email}
            onClick={handleSubmit}
          >
            Notify Me
          </button>
          {errorMessage && <p className="error">{errorMessage}</p>}
          {successfulSubmit && <p className="success">Thank you for your interest!</p>}
        </form>
      </div>
    </div>
  );
}

BottomCTA.propTypes = {
  email: PropTypes.string,
  setFormEmail: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  successfulSubmit: PropTypes.bool,
};

export default BottomCTA;
