/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import thumbnail from '../../assets/PlantGirlCleanWEBP.webp';
import background from '../../assets/PlantGirlDarkenedWEBPMobileGradient4.webp';
import tablet from '../../assets/PlantGirlDarkenedWEBPMobileGradientTablet.webp';

function MainPage({
  email,
  setFormEmail,
  handleSubmit,
  errorMessage,
  setErrorMessage,
  successfulSubmit,
  setSuccessfulSubmit,
}) {
  useEffect(() => {
    let timer;
    if (successfulSubmit) {
      timer = setTimeout(() => {
        setSuccessfulSubmit(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [successfulSubmit]);

  return (
    <div className="main_page">
      <div className="text_content">
        <h1>
          <span className="thrive">Thrive </span>
          <span className="together">Together</span>
        </h1>
        <div className="content_container">
          <div className="company_mission_container">
            <p className="company_mission">We make relationships easy so you can focus on what&apos;s hard.</p>
          </div>
          <div className="form_container">
            <form>
              <label>
                <div className="input_description">
                  Let us tell you when we launch.
                </div>
                <input
                  type="email"
                  className="form_input"
                  value={email}
                  placeholder="example@mail.com"
                  onChange={(event) => {
                    // console.log(event.target.value);
                    setFormEmail(event.target.value);
                    setErrorMessage('');
                  }}
                />
              </label>
              <button
                className="submit_button"
                type="submit"
                value={email}
                onClick={handleSubmit}
              >
                Notify Me
              </button>
              {errorMessage && <p className="error">{errorMessage}</p>}
              {successfulSubmit && <p className="success">Thank you for your interest!</p>}
            </form>
          </div>
        </div>
      </div>
      <div className="image_content"><img src={thumbnail} alt="woman with a blue bird on her shoulder, holding a plant" /></div>
      <div className="mobile_background"><img src={background} alt="woman with a blue bird on her shoulder, holding a plant" /></div>
      <div className="tablet_background"><img src={tablet} alt="woman with a blue bird on her shoulder, holding a plant" /></div>
    </div>

  );
}

MainPage.propTypes = {
  email: PropTypes.string,
  setFormEmail: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  successfulSubmit: PropTypes.bool,
};

export default MainPage;
