import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from '../NavBar';
import './styles.scss';
import Footer from '../Footer';
import Home from '../Home';
import Privacy from '../Privacy';
import TermsOfUse from '../TermsOfUse';
import Legal from '../Legal';
import Page404 from '../Page404';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/legal-notice" element={<Legal />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
